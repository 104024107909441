.App {
  text-align: center;
}

h1 {
  font-weight: bold;
}

body {
  overflow-x: hidden;
}

hr {
  width: 85%;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding-top: .5rem;
}

.navbar {
  position: fixed;
  width: 100%;
}
