.contact-container {
    padding-bottom: 5em;
    padding-top: 1.5em;
    background-color: #eceff4!important;
}

.form-container {
    width: 40%;
    text-align: left;
    margin: auto;
}

.button-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.submit-button {
    width: 100%;
    margin: auto;
}

.success-message {
    width: 40%;
    margin: auto;
    background-color: #4BB543;
    padding: 1rem;
    color: white;
}

.error-message {
    width: 40%;
    margin: auto;
    background-color: #ff0000;
    padding: 1rem;
    color: white;
}   

 /* Tablet & Mobile View */
 @media only screen 
 and (min-device-width: 320px) 
 and (max-device-width: 1024px) 
 and (-webkit-min-device-pixel-ratio: 1) {

     .form-container, .success-message, .error-message {
        width: 90%;
     }

     .submit-button {
        width: 100%;
    }
 }

.contact-heading {
    font-size: calc(3vw + 85%);
}

