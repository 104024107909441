footer {
    width: 100%;
    border-top: 1px solid #d3d3d3;
    padding-top: 1rem;
    padding-bottom: 2rem;
    background-color: #343a40!important; 
    color: #FFFFFF;
  }

/* Desktop View */
.facebook-logo {
    width: 3%;
    height: auto;
}

/* Tablet View */
@media only screen 
and (min-device-width: 768px) 
and (max-device-width: 1024px) 
and (-webkit-min-device-pixel-ratio: 1) {

  .facebook-logo {
    width: 6%;
  }
}

/* Mobile View */
@media only screen 
and (min-device-width: 320px) 
and (max-device-width: 480px) 
and (-webkit-min-device-pixel-ratio: 1) {

  .facebook-logo {
    width: 12%;
  }

  .footer-container {
    width: 90%;
    margin: auto;
  }
}
