.qualifications-container {
    padding-bottom: 2em;
    padding-top: 1em;
}

.qualifications-heading {
    font-size: calc(3vw + 85%);
}

/* Desktop View */
.qualifications-image {
    padding: 1rem;
    width: 25%;
    height: auto;
}

/* Tablet View */
@media only screen 
and (min-device-width: 768px) 
and (max-device-width: 1024px) 
and (-webkit-min-device-pixel-ratio: 1) {

    .qualifications-image {
        width: 40%;
    }
}

/* Mobile View */
@media only screen 
and (min-device-width: 320px) 
and (max-device-width: 480px) 
and (-webkit-min-device-pixel-ratio: 1) {

    .qualifications-image {
        width: 90%;
    }
}
