.banner-container {
    padding-top: 5rem;
  }
  
  .banner-image {
    padding-top: calc(15.0vw);
    padding-bottom:  calc(10.0vw);
    background-image: linear-gradient( rgba(0,0,0,.5), rgba(0,0,0,.5) ), url('/images/banner-image.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
  
      .banner-image h1 {
        font-size: calc(3vw + 80%);
        font-weight: 900;
        color: #FFFFFF;
      }
      
      .banner-image p {
        font-size: calc(1.5vw + 60%);
        font-weight: 200;
        color: #FFFFFF;
      }
  