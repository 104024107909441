.products-container {
    padding-bottom: 5em;
    padding-top: 1.5em;
    background-color: #eceff4!important;
}

/* Desktop View */
.product-card-deck {
    padding-left: calc(16.0vw);
    padding-right: calc(16.0vw);
}

/* Tablet & Mobile View */
@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 1024px) 
  and (-webkit-min-device-pixel-ratio: 1) {

    .product-card-deck {
        padding-left: calc(7.0vw);
        padding-right: calc(7.0vw);
    }
}

.product-card {
    position: static;
}

.products-heading {
    font-size: calc(3vw + 85%);
}